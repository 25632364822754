<template>
    <div class="box">
        <el-carousel height="464px">
            <el-carousel-item v-for="(item, index) in slideshowList" :key="index">
                <img class="lbt" :src="item.picUrl" alt="" />
            </el-carousel-item>
        </el-carousel>
        <!--      咨询动态-->
        <div class="title">
            <img src="@/assets/images/Frame-16.png" alt="" />
            <span style="color: #eda200">资讯</span>
            动态
            <div @click="$router.push({ path: 'consultationDynamicList' })">
                查看更多
                <img src="@/assets/images/Frame-22.png" alt="" />
            </div>
        </div>
        <div class="news">
            <div class="news-left">
                <div class="news-title cu" @click="$router.push({ path: 'informationDetails', query: { id: consultingList[0].id } })" v-if="consultingList.length">{{ consultingList[0].name }}</div>
                <!--                <div class="news-message text-overflow">党的十八大以来，习近平总书记高度重视我国航天事业发展，多次全流程关注神舟十号、神舟十一号等重大航天工程任务进展，作出一系列重要部署，为航天事业发展提供了根本遵循，指明了前进方向。</div>-->
                <div class="news-item" v-show="index > 0" v-for="(item, index) in consultingList" :key="index">
                    <img src="@/assets/images/Rectangle446.png" alt="" />
                    <span @click="$router.push({ path: 'informationDetails', query: { id: item.id } })" class="text-overflow cu">{{ item.name }}</span>
                    <div>{{item.createTime.substr(0,10)}}</div>
                </div>
            </div>
            <el-carousel class="news-right">
                <el-carousel-item v-for="(item, index) in consultingList" :key="index">
                    <img v-if="item.picUrl" class="lbt" :src="item.picUrl.split(',')[0]" alt=""
					 @click="$router.push({ path: 'informationDetails', query: { id: item.id } })" />
                </el-carousel-item>
            </el-carousel>
            <!--            <img class="news-right" src="https://img2.baidu.com/it/u=2513024551,2896067572&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500" alt="" />-->
        </div>
        <div class="title">
            <img src="@/assets/images/Frame-17.png" alt="" />
            <span style="color: #eda200">精彩</span>
            推荐
        </div>
        <div class="wonderful">
            <div @click="$router.push({ path: 'excitingActivitiesDetail', query: { id: item.id } })" v-for="(item, index) in activityList" :key="index">
                <img :src="item.picUrl" alt="" />
                <span class="text-overflow">{{ item.name }}</span>
            </div>
        </div>
        <div class="title">
            <img src="@/assets/images/Frame-18.png" alt="" />
            <span style="color: #eda200">文化</span>
            活动
            <div @click="$router.push({ path: 'excitingActivities' })">
                查看更多
                <img src="@/assets/images/Frame-22.png" alt="" />
            </div>
        </div>
        <!-- <div class="good-list-global">
            <goodListGlobal @click.native="$router.push({ path: 'ActivityDetail', query: { id: item.id } })" v-for="(item, index) in trainingList" :key="index" :item="item"></goodListGlobal>
        </div> -->
        <div class="wonderful">
            <div @click="$router.push({ path: 'ActivityDetail', query: { id: item.id } })" v-for="(item, index) in trainingList" :key="index">
                <img :src="item.picUrl" alt="" />
                <span class="text-overflow">{{ item.name }}</span>
            </div>
        </div>
        <div class="title">
            <img src="@/assets/images/Frame-19.png" alt="" />
            <span style="color: #eda200">璀璨</span>
            空间
        </div>
        <div class="bright">
            <div class="bright-left" v-if="brightspaceList.length" @click="$router.push({ path: 'brightSpace', query: { id: brightspaceList[0].id } })">
                <img :src="brightspaceList[0].picUrl" alt="" />
                <span class="text-overflow">{{ brightspaceList[0].title }}</span>
            </div>
            <div class="bright-right">
                <div @click="$router.push({ path: 'brightSpace', query: { id: item.id } })" v-show="index > 0" v-for="(item, index) in brightspaceList" :key="index">
                    <img :src="item.picUrl" alt="" />
                    <span class="text-overflow">{{ item.title }}</span>
                </div>
            </div>
        </div>
        <!--        <my-footer></my-footer>-->
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import { getSlideshowList, getConsultingList, getActivityList, getTrainingList, getBrightspaceList, getLibraryOtherInfoList } from '@/api';

export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return { slideshowList: [], consultingList: [], activityList: [], trainingList: [], brightspaceList: [], time: '' };
    },
    props: {},
    // 方法集合
    methods: {
        getSlideshowList() {
            getSlideshowList().then((res) => {
                // console.log('轮播图', res);
                this.slideshowList = res.rows;
				this.slideshowList.forEach(ele => {
					let url = ele.picUrl;
					if(url.indexOf('?')<0){
						ele.picUrl = url + '?x-oss-process=image/resize,w_800/quality,q_80'
					}
				})
            });
        },
        getConsultingList() {
            let data = { pageNum: 1, pageSize: 7, libraryId: localStorage.getItem('id') };
            getConsultingList(data).then((res) => {
                // console.log('动态咨询', res);
                this.consultingList = res.rows;
            });
        },
        getActivityList() {
            clearInterval(this.time);
            getActivityList({ libraryId: localStorage.getItem('id'), isRecommend: 1 }).then((res) => {
                // console.log('精彩推荐', res);
                this.activityList = res.rows.slice(0, 4);
            });
        },
        getTrainingList() {
            clearInterval(this.time);
            getTrainingList({ libraryId: localStorage.getItem('id') }).then((res) => {
                // console.log('文化活动', res);
                this.trainingList = res.rows.slice(0, 4);
            });
        },
        //  璀璨空间
        getBrightspaceList() {
            getBrightspaceList().then((res) => {
                // console.log('璀璨空间', res);
                this.brightspaceList = res.rows.slice(0, 5);
            });
        },

        getLibraryOtherInfoList() {
            getLibraryOtherInfoList().then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.libraryOtherInfoList = res.data;
                }
            });
        },
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.getSlideshowList();
        this.getConsultingList();

        this.getBrightspaceList();
        this.time = setInterval(() => {
            if (localStorage.getItem('id')) {
                this.getActivityList();
                this.getTrainingList();
            }
        }, 100);
    },
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.box {
    padding-top: 24px;
}
.lbt {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    margin: 40px 0 24px 0;
    position: relative;
    img {
        width: 32px;
        height: 32px;
        margin-right: 12px;
    }
    div {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        img {
            width: 18px;
            height: 18px;
        }
    }
}
.good-list-global {
    column-count: 3;
}
.news {
    display: flex;
    width: 100%;
    &-left {
        flex: 1;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .news-title {
            font-size: 20px;
            color: #000000;
            margin-bottom: 4px;
        }
        .news-message {
            font-size: 13px;
            color: #999999;
        }
        .news-item {
            display: flex;
            align-items: center;
            margin-top: 16px;
            img {
                width: 10px;
                height: 10px;
                margin-right: 10px;
            }
            span {
                flex: 1;
                margin-right: 10px;
                font-size: 14px;
                color: #333333;
            }
            div {
                font-size: 14px;
                color: #999999;
            }
        }
    }
    &-right {
        width: 420px;
        height: 248px;
        ::v-deep .el-carousel__button {
            width: 10px;
        }
        ::v-deep .el-carousel__container {
            height: 100%;
        }
    }
}
.wonderful {
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr 1fr;
    //grid-gap: 16px;
    column-count: 4;
    column-gap: 16px;
    div {
        display: flex;
        width: 100%;
        flex-direction: column;
        page-break-inside: avoid;
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            margin-bottom: 16px;
        }
        span {
            font-weight: 700;
            font-size: 19px;
            color: rgba(0, 0, 0, 0.8);
        }
    }
    div:nth-child(4) {
        margin-right: 0;
    }
}
.bright {
    display: flex;
    span {
        position: absolute;
        width: 100%;
        bottom: 20px;
        left: 0;
        padding: 0 16px;
        font-size: 20px;
        color: #ffffff;
        //  超出两行隐藏
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        //display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-left {
        width: 380px;
        height: 500px;
        margin-right: 20px;
        position: relative;
    }
    &-right {
        flex: 1;

        column-count: 2;
        column-gap: 20px;
        div {
            width: 100%;
            display: flex;
            margin-bottom: 20px;
            height: 240px;
            position: relative;
        }
    }
}
.el-carousel {
    // width: 500px;
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    /deep/ .el-carousel__indicators {
        // 指示器
        // left: unset;
        transform: unset;
        // right: 2%;
    }
    /deep/ .el-carousel__button {
        // 指示器按钮
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
    }
    /deep/ .is-active .el-carousel__button {
        // 指示器激活按钮
        background: #ffffff;
    }
}
</style>
